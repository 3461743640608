import React from 'react';
import makeup from '../assets/images/home/service/makeup.jpg'
import blackneck from '../assets/images/home/service/blackneck.jpg'
import bleach from '../assets/images/home/service/bleach.jpg'
import bridal from '../assets/images/home/service/bridal.jpg'
import detan from '../assets/images/home/service/detan.jpg'
import facial from '../assets/images/home/service/facial.jpg'
import haircolor from '../assets/images/home/service/haircolor.jpg'
import hairspa from '../assets/images/home/service/hairspa.jpg'
import hairstyle from '../assets/images/home/service/hairstyle.jpg'
import haircare from '../assets/images/home/service/haricare.jpg'
import headmassage from '../assets/images/home/service/headmassage.jpg'
import manicure from '../assets/images/home/service/manicure.jpg'
import pedicure from '../assets/images/home/service/pedicure.jpg'
import skincare from '../assets/images/home/service/skincare.jpg'
import threading from '../assets/images/home/service/threading.jpg'
import waxing from '../assets/images/home/service/waxing.jpg'
import ServiceCard from '../components/ServiceCard';
import { Helmet } from 'react-helmet';
const categorizedServices = [
  {
    mainService: "Makeup & Styling",
    services: [
      {
        id: 1,
        title: "Makeup",
        description: "Professional makeup services for all occasions, whether it's a party, photoshoot, or casual outing. Our skilled makeup artists will ensure you look flawless and beautiful.",
        price: "&#8377;3000",
        image: makeup
      },
      {
        id: 16,
        title: "Bridal Makeup",
        description: "Exclusive bridal makeup services for the big day. We specialize in creating timeless, elegant looks tailored to each bride's individual style, ensuring you feel confident and radiant.",
        price: "&#8377;5000",
        image: bridal
      },
      {
        id: 2,
        title: "Hair Styling",
        description: "Expert hair styling at your convenience. From curls to updos, our stylists are equipped to give you the perfect style for any occasion.",
        price: "&#8377;400",
        image: hairstyle
      },
      {
        id: 13,
        title: "Hair Coloring & Henna Care",
        description: "Professional hair coloring services and henna treatments to rejuvenate and enhance your hair. Whether you're looking for a bold new color or natural henna, we've got you covered.",
        price: "&#8377;999",
        image: haircolor
      }
    ]
  },
  {
    mainService: "Hair & Scalp Care",
    services: [
      {
        id: 4,
        title: "Hair Care",
        description: "Specialized hair care treatments to keep your hair healthy and strong. Our treatments focus on nourishment, repair, and overall hair health.",
        price: "&#8377;999",
        image: haircare
      },
      {
        id: 6,
        title: "Hair Spa",
        description: "Rejuvenating hair spa treatments to nourish your scalp and hair, promoting relaxation and growth. Ideal for those looking to add moisture and shine to their locks.",
        price: "&#8377;999",
        image: hairspa
      },
      {
        id: 5,
        title: "Head Massage",
        description: "Relaxing head massages to relieve stress and tension, stimulate blood circulation, and promote hair growth, leaving you refreshed and relaxed.",
        price: "&#8377;499",
        image: headmassage
      },
      {
        id: 14,
        title: "Bleach",
        description: "Facial and body bleaching services to lighten and brighten your skin, giving it a fresh and rejuvenated appearance.",
        price: "&#8377;50",
        image: bleach
      }
    ]
  },
  {
    mainService: "Skin & Body Care",
    services: [
      {
        id: 3,
        title: "Skincare",
        description: "Personalized skincare treatments for glowing, healthy skin. Our specialists use high-quality products tailored to your skin type to enhance texture and tone.",
        price: "&#8377;999",
        image: skincare
      },
      {
        id: 7,
        title: "Facial & Clean Up",
        description: "Deep-cleansing facials to remove impurities, hydrate your skin, and restore a youthful glow. We offer a variety of facials to suit all skin types and concerns.",
        price: "&#8377;999",
        image: facial
      },
      {
        id: 8,
        title: "Black Neck Treatment",
        description: "Special treatments to lighten and cleanse the neck area, targeting pigmentation and improving skin texture for a more even appearance.",
        price: "&#8377;499",
        image: blackneck
      },
      {
        id: 15,
        title: "Detan",
        description: "Special detan treatments to remove sun tan and rejuvenate the skin, leaving it lighter and more radiant.",
        price: "&#8377;499",
        image: detan
      }
    ]
  },
  {
    mainService: "Nail & Waxing",
    services: [
      {
        id: 9,
        title: "Threading",
        description: "Precision threading services for perfectly shaped eyebrows and facial hair removal, ensuring a clean and polished look.",
        price: "&#8377;100",
        image: threading
      },
      {
        id: 10,
        title: "Manicure",
        description: "Professional manicure services to clean, shape, and beautify your nails, providing a pampered and polished appearance.",
        price: "&#8377;499",
        image: manicure
      },
      {
        id: 11,
        title: "Pedicure",
        description: "Soothing pedicure treatments for your feet, including nail trimming, callus removal, and a relaxing massage to leave your feet soft and beautiful.",
        price: "&#8377;499",
        image: pedicure
      },
      {
        id: 12,
        title: "Waxing",
        description: "Efficient and gentle waxing services for hair removal, leaving your skin smooth and hair-free with minimal discomfort.",
        price: "&#8377;666",
        image: waxing
      }
    ]
  }
];

const ServicesPage = () => (
  <>
  <Helmet>
  <title>Glowspot Up: Best Makeup Artist in Tirunelveli | Beauty Services</title>
  <meta name= "description" content="Glowspotup, Tirunelveli's Best Makeup Artist, Offers Expert Beauty Services, from Bridal Makeup to Skincare. Visit Us for a Flawless Transformation!" />
  <meta name= "keywords" content ="Best Makeup Artist in Tirunelveli" />
  <meta name= "robots" content ="index, follow" />
  <meta properties= "og:title" content= "Glowspot Up: Best Makeup Artist in Tirunelveli | Beauty Services" />
  <meta properties= "og:description" content= "Glowspotup, Tirunelveli's Best Makeup Artist, Offers Expert Beauty Services, from Bridal Makeup to Skincare. Visit Us for a Flawless Transformation!" />
  <meta properties= "og:url" content= "https://glowspotup.com/services" />
  <meta properties= "og:image" content= "https://glowspotup.com/static/media/logo.f260d610fa3bbd16b2f4.png" />
  <meta properties= "og:type" content= "website" />
  </Helmet>
  <link rel= "canonical" href= "https://glowspotup.com/services"></link>
  <div className="bg-purple-50 min-h-screen p-6">
      <h1 className="text-4xl font-extrabold text-center text-purple-700 mb-6">Our Services</h1>
      {categorizedServices.map((category) => (
        <div key={category.mainService} className="mb-12">
          <h2 className="text-3xl font-semibold text-purple-600 mb-4">{category.mainService}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {category.services.map((service) => (
              <ServiceCard key={service.id} service={service} />
            ))}
          </div>
        </div>
      ))}
    </div>
  </>

);

export default ServicesPage;
